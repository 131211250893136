import React from 'react';

const FooterDetails = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p className="footer-title">Mediatek Application</p>
        <p className="footer-copy">&copy; All rights reserved.</p>
      </div>
    </footer>
  );
};

export default FooterDetails;
