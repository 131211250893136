import image1 from '../assets/images/mtk_main_logo.png';
const siteOptionData = [
    {
      id: 1,
      siteName: "Mediatek Application",
      image: image1,
      description: "Experience a secure and reliable VPN service with enhanced privacy and performance.",
      siteLink: "https://mtk-tunnel.xyz"
    }
  ];
  export default siteOptionData;