import image1 from '../assets/images/Genuine_MTk_V24.png';
import image2 from '../assets/images/MTK_TUNNEL.png';
import image3 from '../assets/images/Cyberx _Vpn.png';
import image4 from '../assets/images/MTK_ULTRA_V7.png';
import image5 from '../assets/images/DEX_VIP_VPN.png';
import image6 from '../assets/images/MTK_REBORN_V5.png';
import image7 from '../assets/images/Metroid_Fusion.png';



const productData = [
    {
      id: 1,
      name: "GENUINE MTK V24",
      image: image1,
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY"],
      rating: 5,
      timeLeft: 27,
      reseller: "https://mtk-tunnel.xyz",
      appLink: "https://apps.mvpn-tunnel.click/genuine-mtk.apk"
    },
    
    {
      id: 2,
      name: "MTK TUNNEL",
      image: image2,
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY", "SSH", "SLOWDNS"],
      rating: 5,
      timeLeft: 27,
      reseller: "https://mtk-tunnel.xyz",
      appLink: "https://apps.mvpn-tunnel.click/mtk-tunnel.apk"
    },
    {
      id: 3,
      name: "CyberX Vpn",
      image: image3,
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY"],
      rating: 5,
      timeLeft: 24,
      reseller: "https://mvpn-tunnel.click",
      appLink: "https://apps.mvpn-tunnel.click/cyberx.apk"
    },
    {
      id: 4,
      name: "MTK ULTRA V7",
      image: image4,
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY"],
      rating: 5,
      timeLeft: 24,
      reseller: "https://mtk-tunnel.xyz",
      appLink: "https://apps.mvpn-tunnel.click/mtk-ultra.apk"
    },
    {
      id: 5,
      name: "DEX VIP VPN",
      image: image5,
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY"],
      rating: 5,
      timeLeft: 24,
      reseller: "https://mtk-tunnel.xyz",
      appLink: "https://apps.mvpn-tunnel.click/dex-vip-vpn.apk"
    },
    {
      id: 6,
      name: "MEDIATEK OVPN",
      image: image6,
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY"],
      rating: 5,
      timeLeft: 24,
      reseller: "https://mtk-tunnel.xyz",
      appLink: "https://apps.mvpn-tunnel.click/mtk-reborn-v5.apk"
    },
    {
      id: 7,
      name: "Metroid Fusion",
      image: image7,
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY"],
      rating: 5,
      timeLeft: 24,
      reseller: "https://mvpn-tunnel.click",
      appLink: "https://apps.mvpn-tunnel.click/metroid-fusion.apk"
    }
  ];
  export default productData;
